import { Controller } from "stimulus";
import "selectize.js/dist/js/standalone/selectize.min";

export default class extends Controller {
  static values = {
    list: Array,
    id: String,
    create: Boolean,
    column: String,
    obj: String,
    selected: Array,
    max: Number,
  };

  connect() {
    const createVal = this.createValue;
    const column = this.columnValue;
    const obj = this.objValue;
    const list = this.listValue;
    const max = this.maxValue;
    const selected = this.selectedValue;

    $(`#${this.idValue}`).selectize({
      delimiter: ",",
      options: list || [],
      maxItems: max || 1,
      labelField: "title",
      valueField: "value",
      items: selected || [],
      searchField: "title",
      render: {
        option_create: function (data, escape) {
          var addString = "添加";
          return (
            '<div class="create">' +
            addString +
            " <strong>" +
            escape(data.input) +
            "</strong>&hellip;</div>"
          );
        },
      },
      create: createVal
        ? function (input) {
            return {
              value: input,
              title: input,
            };
          }
        : false,
      load: function (query, callback) {
        if (!query.length) return callback();
        const accountId = $("#account-id").val();
        $.ajax({
          url: "/api/v1/accounts",
          data: {
            obj: obj,
            id: accountId,
            column: column,
            query: query,
          },
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res);
          },
        });
      },
    });
  }
}
