import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  handleRoleChange() {
    if ($('#account_role').val() === 'company') {
      $('.company-only').show()
    } else {
      $('.company-only').hide()
    }
  }
}