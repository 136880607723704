// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import toastr from "toastr";
import jquery from "jquery";
import "../controllers";
window.$ = jquery;
window.jQuery = jquery;
Rails.start();
ActiveStorage.start();
window.toastr = toastr;

toastr.options = {
  closeButton: false,
  debug: false,
  positionClass: "toast-top-right",
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  progressBar: true,
};

import "toastr/build/toastr.min";
import "@client-side-validations/client-side-validations/src";
import "@client-side-validations/simple-form/src/index.bootstrap4";
