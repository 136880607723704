import { Controller } from "stimulus";
import { findElement } from "helpers";

export default class extends Controller {
  static targets = ["input"];
  connect() {
    this.submitButton.disabled = true;
  }
  get form() {
    return this.element.closest("form");
  }
  get submitButton() {
    return findElement(this.form, "input[type=submit], button[type=submit]");
  }
  agreeChange() {
    if (this.inputTarget.checked) {
      this.submitButton.disabled = false;
    } else {
      this.submitButton.disabled = true;
    }
  }
}
