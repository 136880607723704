import { Controller } from "stimulus";
import "selectize.js/dist/js/standalone/selectize.min";

export default class extends Controller {
  static values = {
    list: Array,
    id: String,
    obj: String,
    selected: String,
  };

  connect() {
    const list = this.listValue.map(x => { return {title: x, value: x} } );
    const selected = this.selectedValue;
    $(`#${this.idValue}`).selectize({
      delimiter: ",",
      options: list || [],
      labelField: "title",
      valueField: "value",
      items: [selected] || [],
      render: {
        option_create: function (data, escape) {
          var addString = "添加";
          return (
            '<div class="create">' +
            addString +
            " <strong>" +
            escape(data.input) +
            "</strong>&hellip;</div>"
          );
        },
      }
    });
  }
}
