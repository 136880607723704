import { Controller } from "stimulus";
import Hls from "hls.js";

export default class extends Controller {
  // static targets = ['image', "video"];
  static targets = ["video"];

  connect() {
    this.initPlay();
  }

  // disconnect() {}

  palyOnPopup() {
    const url = this.videoTarget.dataset.videoUrl;
    const accessToken = this.videoTarget.accessToken;
    $("#grid-modal-content")
      .empty()
      .append(
        `<video id='video-modal' controls style="width:100%;height:100%" data-target="m3u8.video" data-video-url="${url}"></video>`
      );
    $("#grid-modal").modal("show");
    this.playHLS(url, document.getElementById("video-modal"), true, accessToken);
  }

  initPlay() {
    const video = this.videoTarget;
    const videoSrc = this.videoTarget.dataset.videoUrl;
    const accessToken = this.videoTarget.accessToken;
    video.muted = "muted";
    if (Hls.isSupported() && videoSrc !== "") {
      if (video.tagName === "VIDEO") {
        this.playHLS(videoSrc, video, true, accessToken);
      }
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoSrc;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  playHLS(videoSrc, video, autoPlay = true, accessToken) {
    const hls = new Hls({
      licenseXhrSetup: function (xhr, url) {
        xhr.withCredentials = true; // do send cookies
        if (!xhr.readyState) {
          // Call open to change the method (default is POST) or modify the url
          xhr.open('GET', url, true);
          // Append headers after opening
          xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        }
      },
    });
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (autoPlay) {
        video.play();
      } else {
        setTimeout(() => {
          hls.stopLoad();
        }, 5000);
      }
    });
  }
}
